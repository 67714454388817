import React, { useEffect, useLayoutEffect, useMemo } from "react";
import { Form, FormInstance } from "antd";
import dayjs from "dayjs";
import { useTranslation } from "react-i18next";

import { useAuth } from "modules/auth/hooks";

import { useAppSelector } from "../../../../../../hooks/redux";
import ConditionalRender from "../../../../../app/components/conditional-render/ConditionalRender";
import { useGetCustomFieldSelect } from "../../../../../app/service/queries";
import { colors } from "../../../../../app/utils/constants/colors";
import { dayjsFormats } from "../../../../../app/utils/constants/dayjsFormats";
import { RU } from "../../../../../app/utils/constants/languages";
import { CustomFieldLocationEnum } from "../../../../../app/utils/enums/customFieldLocationEnum";
import { isEmptyArr } from "../../../../../app/utils/helpers/isEmptyArr";
import { localeFormatter } from "../../../../../app/utils/helpers/localeFormatter";
import { useGetCompanyPerson } from "../../../../../counterparts/service/queries";
import { INCOME_EXPENSE_TYPE, IncomeExpenseType } from "../../../../utils/enums/incomeExpenseTypeEnums";
import { passedCustomFieldsData } from "../../../../utils/helpers/paymentCustomField";

import Additional from "./additional/Additional";
import AmountItem from "./amount/AmountItem";
import EstimateDrawer from "./estimate-drawer/EstimateDrawer";
import ProjectItem from "./project/ProjectItem";
import ResourceType from "./resource-type/ResourceType";

import styles from "./thirdStep.module.scss";

type Props = {
  step: number;
  form: FormInstance;
};

const incomeExpenseGetType = {
  financial: INCOME_EXPENSE_TYPE.financial_id,
  inventory: INCOME_EXPENSE_TYPE.inventory_id,
  company_person: INCOME_EXPENSE_TYPE.company_person_id
};

const ThirdStep: React.FC<Props> = ({ step, form }) => {
  const { t } = useTranslation();
  const { currencies } = useAuth();
  const project_id = Form.useWatch("project_id", form);
  const company_person_id = Form.useWatch("company_person_id", form);
  const incomeExpenseType = Form.useWatch("income_expense_type", form);

  const { currency, defaultData } = useAppSelector(state => state.paymentReducer.income_expense_modal);

  const { data: getCompanyPerson } = useGetCompanyPerson(Number(company_person_id));
  const { data: customFields } = useGetCustomFieldSelect([CustomFieldLocationEnum.PAYMENT]);

  const activeCurrency = useMemo(
    () => localeFormatter(String(currencies?.find(item => item.id === currency?.id)?.amount) ?? "1"),
    [currency, currencies]
  );

  useLayoutEffect(() => {
    form.setFieldValue("currency_amount", activeCurrency);
  }, [currency, step, form, activeCurrency]);

  useEffect(() => {
    if (defaultData?.id) {
      const incomeExpenseType = Object.keys(incomeExpenseGetType).reduce((p, c) => {
        if (defaultData[c as keyof typeof defaultData]) {
          p = c;
        }

        return p;
      }, "financial_id") as IncomeExpenseType;

      const income_expense_key = incomeExpenseGetType[incomeExpenseType as keyof typeof incomeExpenseGetType];

      form.setFieldsValue({
        project_id: defaultData?.project?.id,
        description: defaultData?.description,
        income_expense_type: income_expense_key,
        date: dayjs(defaultData?.date, dayjsFormats.DATE),
        [income_expense_key]: defaultData[incomeExpenseType]?.id,
        financial: incomeExpenseType === "inventory" ? defaultData?.financial?.id : undefined,
        custom_field_values: passedCustomFieldsData(defaultData?.custom_field_values, customFields)
      });
    }
  }, [defaultData, form, customFields]);

  const personColorStyle = (amount: number): React.CSSProperties => {
    if (amount > 0) {
      return {
        background: colors.SUCCESS_50,
        color: colors.SUCCESS_500
      };
    }
    if (amount < 0) {
      return {
        background: colors.ERROR_50,
        color: colors?.ERROR_500
      };
    }

    return {
      background: colors.GRAY_50,
      color: colors.GRAY_500
    };
  };

  return (
    <div className={styles.thirth}>
      <ProjectItem form={form} />
      <AmountItem form={form} activeCurrency={activeCurrency} />
      <ResourceType step={step} form={form} projectId={project_id} incomeExpenseType={incomeExpenseType} />
      <ConditionalRender if={company_person_id}>
        <div className={styles.balance}>
          <span className={styles.balance__title}>{t("payment.Balans")}:</span>
          <ConditionalRender
            if={!isEmptyArr(getCompanyPerson?.balances || [])}
            else={
              <span className={styles.person_balance} style={personColorStyle(0)}>
                {t("payment.Mavjud emas")}
              </span>
            }
          >
            {getCompanyPerson?.balances?.map(item => (
              <div key={item?.id} className={styles.person_balance} style={personColorStyle(item.amount)}>
                <span>{item?.amount?.toLocaleString(RU)}</span>
                <span>{item?.currency?.symbol}</span>
              </div>
            ))}
          </ConditionalRender>
        </div>
      </ConditionalRender>
      <Additional form={form} />
      <EstimateDrawer />
    </div>
  );
};

export default ThirdStep;
