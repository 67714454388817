import React from "react";
import { Col, Form, Input, Row } from "antd";
import { Rule } from "antd/es/form";
import { FormInstance } from "antd/lib";
import { useTranslation } from "react-i18next";

import { isEmptyArr } from "modules/common";

import { useAppSelector } from "../../../../../../../hooks/redux";
import ConditionalRender from "../../../../../../app/components/conditional-render/ConditionalRender";
import { formRules } from "../../../../../../app/utils/constants/formRules";
import { RU } from "../../../../../../app/utils/constants/languages";
import { PaymentTypeEnum } from "../../../../../../app/utils/constants/paymentTypeEnum";
import { getBaseCurrency } from "../../../../../../app/utils/helpers/baseCurrency";
import { localeFormatter } from "../../../../../../app/utils/helpers/localeFormatter";
import { parseLocaledString } from "../../../../../../app/utils/helpers/parseLocaledString";

const { Item } = Form;

type Props = {
  form: FormInstance;
  activeCurrency: string;
};

const AmountItem: React.FC<Props> = ({ form, activeCurrency }) => {
  const baseCurrency = getBaseCurrency();
  const { currency, type, defaultData } = useAppSelector(state => state.paymentReducer.income_expense_modal);
  const isCurrencyAmount = baseCurrency?.id !== currency?.id;
  const { t } = useTranslation();

  const amountRule: Rule[] = [
    ...formRules(),
    {
      validator: (_, value) => {
        const amount = parseLocaledString(value || "0");

        if (type !== PaymentTypeEnum.INCOME) {
          if (amount > currency!.amount)
            return Promise.reject(
              `${t("payment.Kiritilgan miqdor")} ${currency?.amount?.toLocaleString(RU)} ${currency?.symbol} ${t("payment.dan oshmasligi kerak")}`
            );

          return Promise.resolve();
        }

        return Promise.resolve();
      }
    }
  ];

  const onChangeAmount = (e: React.ChangeEvent<HTMLInputElement>) => {
    form.setFieldValue("amount", localeFormatter(e.currentTarget.value));
  };

  const onChangeCurrencyAmount = (e: React.ChangeEvent<HTMLInputElement>) => {
    form.setFieldValue("currency_amount", localeFormatter(e.currentTarget.value));
  };

  return (
    <Row gutter={16}>
      <Col span={isCurrencyAmount ? 14 : 24}>
        <Item name="amount" label={t("payment.Summa")} rules={amountRule}>
          <Input
            disabled={defaultData?.order_ids && !isEmptyArr(defaultData?.order_ids)}
            placeholder={t("payment.Summa")}
            onChange={onChangeAmount}
            suffix={currency?.symbol}
          />
        </Item>
      </Col>
      <ConditionalRender if={isCurrencyAmount}>
        <Col span={10}>
          <Item
            name="currency_amount"
            label={t("payment.Valyuta kursi")}
            rules={formRules()}
            initialValue={activeCurrency}
          >
            <Input
              placeholder={t("payment.Summa")}
              onChange={onChangeCurrencyAmount}
              prefix={`${currency?.symbol} = `}
              suffix={baseCurrency?.symbol}
            />
          </Item>
        </Col>
      </ConditionalRender>
    </Row>
  );
};

export default AmountItem;
