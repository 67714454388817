import React from "react";
import { Form, Select } from "antd";
import { useTranslation } from "react-i18next";

import { useAppSelector } from "hooks/redux";

import { isEmptyArr } from "modules/common";

import SelectSuffixIcon from "../../../../../../../app/assets/icons/SelectSuffixIcon";
import TableEmpty from "../../../../../../../app/components/table-empty/TableEmpty";
import { formRules } from "../../../../../../../app/utils/constants/formRules";
import { selectFilterOption } from "../../../../../../../app/utils/helpers/selectFilterOption";
import { INCOME_EXPENSE_TYPE } from "../../../../../../utils/enums/incomeExpenseTypeEnums";

const { Item } = Form;
const { Option } = Select;

type Props = {
  search: string;
  projectId: number;
  incomeExpenseType: any;
  isLoadingFinancial?: boolean;
  isLoadingInventories?: boolean;
  isLoadingCompanyPerson?: boolean;
  NotFoundContent: () => JSX.Element;
  setSearch: React.Dispatch<React.SetStateAction<string>>;
  incomeExpenseTypeData: Record<string, { id: number; name: string; tin?: string }[]>;
};

const IncomeExpenseTypeSelectItem: React.FC<Props> = ({
  setSearch,
  NotFoundContent,
  incomeExpenseType,
  isLoadingFinancial,
  isLoadingInventories,
  incomeExpenseTypeData,
  isLoadingCompanyPerson
}) => {
  const { t } = useTranslation();
  const { defaultData } = useAppSelector(state => state.paymentReducer.income_expense_modal);

  return (
    <Item name={incomeExpenseType} rules={formRules()}>
      <Select
        showSearch
        onSearch={setSearch}
        disabled={defaultData?.order_ids && !isEmptyArr(defaultData?.order_ids)}
        placeholder={t("payment.Tanlang")}
        optionLabelProp="name"
        suffixIcon={<SelectSuffixIcon />}
        filterOption={selectFilterOption}
        loading={isLoadingFinancial || isLoadingCompanyPerson || isLoadingInventories}
        notFoundContent={incomeExpenseType === INCOME_EXPENSE_TYPE.inventory_id ? <TableEmpty /> : <NotFoundContent />}
      >
        {incomeExpenseTypeData[incomeExpenseType as keyof typeof incomeExpenseTypeData]?.map(item => (
          <Option
            key={item?.id}
            value={item?.id}
            props={{
              name: `${item?.name} ${item?.tin}`
            }}
            name={item?.name}
          >
            <span className="flex justify-between">
              {item?.name} <span>{item?.tin || ""}</span>
            </span>
          </Option>
        ))}
      </Select>
    </Item>
  );
};

export default IncomeExpenseTypeSelectItem;
